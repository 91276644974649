@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Yeseva+One&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  background-color: #010026;
  @apply text-white;
}

#hero-pic {
  animation: right-mover 1s ease-in-out infinite alternate;
}

@keyframes right-mover {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}

@media only screen and (max-width: 768px) {
  #hero-pic {
    animation: none;
  }
}

/* PHOTO GALLERY */

.lg-react-element {
  column-count: 3;
  column-gap: 10px;
}

#examples img,
.lg-image {
  max-width: 100%;
  display: block;
  padding: 5px 0px;
  border-radius: 20px;
  transition: transform 0.2s;
}

#examples img:hover {
  filter: opacity(0.9);
  transform: scale(1.01);
}

.map {
  grid-area: map;
  height: 100%;
  width: 100%;
}

.contact {
  padding: 40px;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.15);
  height: 100%;
  width: 100%;
}

iframe {
  height: 100%;
  width: 100%;
}

.wavy {
  text-decoration-style: wavy;
}
